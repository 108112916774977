import React from "react";

const FormCard = ({ children, title }) => (
    <div className="bg-white p-8 rounded shadow-sm">
        <div className="space-y-4">
            <h4 className="font-semibold text-xl">{title}</h4>
            {children}
        </div>
    </div>
);

export default FormCard;
