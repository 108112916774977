/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "../components";
import initializeApps from "../mini_apps";
import populateAddressOptions from "../utils/populateAddressOptions";
import billingSameAsHome from "../utils/billingSameAsHome";
import datepicker from "js-datepicker";
import * as $ from "jquery";
import "sharer.js";

require("js-datepicker/dist/datepicker.min.css");
require("stylesheets/application.scss");

Rails.start();
Turbolinks.start();
ActiveStorage.start();

initializeApps();

/* Dashboard progress ring */

function initializeProgressRing() {
    var circle = document.querySelector("circle.overlay");
    var ring = document.querySelector(".js-progressRing");

    if (circle && ring) {
        var radius = circle.r.baseVal.value;
        var circumference = radius * 2 * Math.PI;

        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = `${circumference}`;

        function setProgress(percent) {
            const offset = circumference - (percent / 100) * circumference;
            circle.style.strokeDashoffset = offset;
        }

        setProgress(+ring.dataset.complete);
    }
}

function initDateTimePicker() {
    const selectors = document.querySelectorAll(".js-datepicker");
    const nodes = Array.prototype.slice.call(selectors, 0);
    if (nodes?.length > 0) {
        [...nodes].forEach((selector) => {
            datepicker(selector, {
                formatter: (input, date, instance) => {
                    const value = date.toLocaleDateString();
                    input.value = value;
                },
            });
        });
    }

    const todayselectors = document.querySelectorAll(".js-datepicker-today");
    const todaynodes = Array.prototype.slice.call(todayselectors, 0);
    if (todaynodes?.length > 0) {
        [...todaynodes].forEach((selector) => {
            datepicker(selector, {
                formatter: (input, date, instance) => {
                    const value = date.toLocaleDateString();
                    input.value = value;
                },
                maxDate: new Date()
            });
        });
    }
}

function initMobileNav() {
    const $trigger = document.querySelector(".js-mobile-nav-trigger");
    const $targetNav = document.querySelector(".js-mobile-nav");
    if ($trigger && $targetNav) {
        $trigger.addEventListener("click", () => {
            $targetNav.classList.toggle("hidden");
        });
    }
}

function fetch_provider_provinces(region_id, hook) {
    $.ajax({
        type: "GET",
        url: "/public/regions/" + region_id + "/provinces",
    }).done(function (data) {
        var province_select = $(
            ".js-provider-province[data-hook='" + hook + "']"
        );
        var area_select = $(".js-provider-area[data-hook='" + hook + "']");

        province_select.empty();
        province_select.append(new Option("Choose One", null, true, true));
        area_select.empty();
        area_select.append(new Option("Choose One", null, true, true));

        for (var province of data) {
            var option = new Option(province.name, province.name, false, false);
            option.dataset.id = province.id;
            province_select.append(option);
        }
    });
}

function fetch_provider_areas(province_id, hook) {
    $.ajax({
        type: "GET",
        url: "/public/provinces/" + province_id + "/areas",
    }).done(function (data) {
        var area_select = $(".js-provider-area[data-hook='" + hook + "']");

        area_select.empty();
        area_select.append(new Option("Choose One", "", true, true));

        for (var area of data) {
            var option = new Option(area.name, area.name, false, false);
            option.dataset.id = area.id;
            area_select.append(option);
        }
    });
}

function _fetchProviderProvinces(e) {
    var hook = $(this).data("hook");
    var region_id = $(this).children("option:selected").data("id");
    fetch_provider_provinces(region_id, hook);
}

function _fetchProviderAreas(e) {
    var hook = $(this).data("hook");
    var province_id = $(this).children("option:selected").data("id");
    fetch_provider_areas(province_id, hook);
}

function _providerArea(e) {
    var hook = $(this).data("hook");
    var area_id = $(this).children("option:selected").data("id");
    $(".js-provider-area-id[data-hook='" + hook + "']").val(area_id);
}

function initAddressSelection() {
    $(document).on("change", ".js-provider-region", _fetchProviderProvinces);
    $(document).on("change", ".js-provider-province", _fetchProviderAreas);
    $(document).on("change", ".js-provider-area", _providerArea);
}

function clearSessionStorageOnClick() {
    const links = document.querySelectorAll("[href='/provider/programs/new']");
    if (links.length > 0) {
        [...links].forEach(function (el) {
            el[0].addEventListener("click", () => {
                sessionStorage.removeItem("programInitialValue");
            });
        });
    }
}

function initLicenseForm() {
    const $containerEl = $(".js-doc-template");
    const $template = $containerEl.children("div").first();
    function add_licence_credential_field(e) {
        e.preventDefault();
        const $el = $template.clone();
        $el.find("input, select, textarea").each(function () {
            this.name = this.name.replace(
                "[0]",
                `[${$containerEl.children("div").length}]`
            );
            this.id = this.id.replace(
                "_0_",
                `_${$containerEl.children("div").length}_`
            );
        });

        $el.find("label").each(function () {
            this.id = this.id.replace(
                "_0_",
                `_${$containerEl.children("div").length}_`
            );
            this.htmlFor = this.htmlFor?.replace(
                "_0_",
                `_${$containerEl.children("div").length}_`
            );
        });

        const providerRegion = $el.find(".js-professional-region");
        const providerProvince = $el.find(".js-professional-province");
        const providerArea = $el.find(".js-professional-area");

        if (providerRegion) {
            console.log(providerRegion);
            $(providerRegion).on("change", _fetchProviderProvinces);
        }

        if (providerProvince) {
            $(providerProvince).on("change", _fetchProviderAreas);
        }

        if (providerArea) {
            $(providerArea).on("change", _providerArea);
        }

        // $el.find(".js-datepicker").each(function () {
        //     console.log(this);
        //     datepicker(this, {
        //         formatter: (input, date, instance) => {
        //             const value = date.toLocaleDateString();
        //             input.value = value;
        //         },
        //     });
        // });
        $containerEl.append($el);
    }
    function remove_licence_credential_field(e) {
        e.preventDefault();
    }

    $(".js-add-licence-credential").on("click", add_licence_credential_field);
    $(".credentials-container").on(
        "click",
        ".js-remove-licence-credential",
        remove_licence_credential_field
    );
}

function initSharer() {
    window.Sharer.init();
}

function initScripts() {
    initializeProgressRing();
    populateAddressOptions();
    billingSameAsHome();
    initDateTimePicker();
    initMobileNav();
    initAddressSelection();
    // initLicenseForm();
    clearSessionStorageOnClick();
    initSharer();
}

document.addEventListener("turbolinks:load", initScripts);
