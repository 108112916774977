import { InMemoryCache, ApolloClient } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                programs: relayStylePagination(),
            },
        },
    },
});

const client = new ApolloClient({
    uri: "/graphql",
    cache,
});

export default client;
