import { getAreas, getProvinces } from "./fetchOptions";

function resetSelect(target) {
    const emptyOption = new Option("Choose One", null, true, true);
    emptyOption.disabled = true;
    target.innerHTML = "";
    target.append(emptyOption);
}

function updateProvinceOptions(e) {
    const select = e.currentTarget;
    const { options, selectedIndex } = select;
    const $selected = options[selectedIndex];
    const hook = select.dataset.hook;
    const $targetProvince = document.querySelector(
        `.js-professional-province[data-hook=${hook}]`
    );
    const $targetArea = document.querySelector(
        `.js-professional-area[data-hook=${hook}]`
    );

    /* Reset province and area select options */
    resetSelect($targetProvince);
    resetSelect($targetArea);

    getProvinces($selected.dataset.id).then((opts) => {
        opts.forEach((o) => {
            const opEl = new Option(o.name, o.name, false, false);
            opEl.dataset.id = o.id;

            $targetProvince[$targetProvince.options.length] = opEl;
        });
    });
}

function updateAreaOptions(e) {
    const select = e.currentTarget;
    const { options, selectedIndex } = select;
    const $selected = options[selectedIndex];
    const hook = select.dataset.hook;
    const $targetArea = document.querySelector(
        `.js-professional-area[data-hook=${hook}]`
    );

    resetSelect($targetArea);

    if (!$selected.dataset.id) {
        return null;
    }
    getAreas($selected.dataset.id).then((opts) => {
        opts.forEach((o) => {
            const opEl = new Option(o.name, o.name, false, false);
            opEl.dataset.id = o.id;

            $targetArea[$targetArea.options.length] = opEl;
        });
    });
}

function populateAddressOptions() {
    const $regionSelectors = document.querySelectorAll(
        ".js-professional-region"
    );
    const $provinceSelectors = document.querySelectorAll(
        ".js-professional-province"
    );

    $regionSelectors.forEach(($selector) => {
        $selector.addEventListener("change", updateProvinceOptions);
    });
    $provinceSelectors.forEach(($selector) => {
        $selector.addEventListener("change", updateAreaOptions);
    });
}

export default populateAddressOptions;
