import React, { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";

const DateField = ({ form, field, type, id, minDate }) => {
    const isDateTimeLocal = type === "datetime-local";
    return (
        <DatePicker
            id={id}
            selected={field.value ? new Date(field.value) : null}
            className="text-input"
            minDate={minDate ? minDate : ""}
            dateFormat={
                isDateTimeLocal ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"
            }
            showTimeInput={isDateTimeLocal}
            onChange={(date) => {
                form.setFieldValue(field.name, date);
            }}
        />
    );
};
export default DateField;
