import axios from "axios";

const provinceURL = (regionId) => `/public/regions/${regionId}/provinces`;
const areaURL = (provinceId) => `/public/provinces/${provinceId}/areas`;

export function getProvinces(regionId) {
    return axios.get(provinceURL(regionId)).then(
        (res) => res.data,
        (err) => console.warn
    );
}

export function getAreas(provinceId) {
    return axios.get(areaURL(provinceId)).then(
        (res) => res.data,
        (err) => console.warn
    );
}
