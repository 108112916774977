function billingSameAsHome() {
    const checkbox = document.querySelector("#defaultCheck1");

    if (checkbox) {
        const homeRegion = document.querySelector("#professional_home_region");
        const homeProvince = document.querySelector(
            "#professional_home_province"
        );
        const homeArea = document.querySelector("#professional_home_area");
        const homeLine1 = document.querySelector("#professional_home_line_1");
        const homeLine2 = document.querySelector("#professional_home_line_2");

        const billingRegion = document.querySelector(
            "#professional_billing_region"
        );
        const billingProvince = document.querySelector(
            "#professional_billing_province"
        );
        const billingArea = document.querySelector(
            "#professional_billing_area"
        );
        const billingLine1 = document.querySelector(
            "#professional_billing_line_1"
        );
        const billingLine2 = document.querySelector(
            "#professional_billing_line_2"
        );

        const checked = [
            homeRegion.value === billingRegion.value,
            homeProvince.value === billingProvince.value,
            homeArea.value === billingArea.value,
        ].every(Boolean);

        checkbox.checked = checked;
        billingRegion.disabled = checkbox.checked;
        billingProvince.disabled = checkbox.checked;
        billingArea.disabled = checkbox.checked;
        billingLine1.disabled = checkbox.checked;
        billingLine2.disabled = checkbox.checked;

        checkbox.addEventListener("change", (e) => {
            if (e.currentTarget.checked) {
                billingRegion.value = homeRegion.value;
                billingProvince.innerHTML = homeProvince.innerHTML;
                billingProvince.value = homeProvince.value;
                billingArea.innerHTML = homeArea.innerHTML;
                billingArea.value = homeArea.value;
                billingLine1.value = homeLine1.value;
                billingLine2.value = homeLine2.value;

                billingRegion.disabled = true;
                billingProvince.disabled = true;
                billingArea.disabled = true;
                billingLine1.disabled = true;
                billingLine2.disabled = true;
            } else {
                billingRegion.disabled = false;
                billingProvince.disabled = false;
                billingArea.disabled = false;
                billingLine1.disabled = false;
                billingLine2.disabled = false;
            }
        });
    }
}

export default billingSameAsHome;
