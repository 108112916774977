import animateCSS from "../javascript/utils/animateCSS";
function init() {
    const el = document.querySelector(".js-Alert");
    if (el) {
        const isAutoDismiss = el.classList.contains("js-AutoDismiss");
        let timeoutId, btn;

        function hideAlert() {
            animateCSS(el, "fadeOut").then((node) => {
                node.remove();
                timeoutId && clearTimeout(timeoutId);
                if (btn) {
                    btn = null; /* remove reference to clear its listener*/
                }
            });
        }

        if (isAutoDismiss) {
            const timeout = el.dataset.timeout;
            timeoutId = setTimeout(hideAlert, timeout);
        }

        btn = el.querySelector("button");
        btn.addEventListener("click", hideAlert);
    }
}

document.addEventListener("turbolinks:load", init);
