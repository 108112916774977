import React from "react";
import { CameraIcon, XIcon } from "@heroicons/react/solid";

const HeaderInput = ({ field, form }) => {
    const removeFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        form.setFieldValue(field.name, "");
    };

    const handleChange = async (e) => {
        const file = e.currentTarget.files[0];
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = await function (event) {
            const base64String = event.target?.result;
            form.setFieldValue(field.name, base64String);
        };
    };

    return (
        <div className="relative h-48 w-72">
            <label
                className="w-full h-full bg-gray-200 rounded flex items-center justify-center bg-center bg-cover"
                style={{ backgroundImage: `url(${field.value})` || "" }}
            >
                {!field.value && (
                    <CameraIcon className="h-8 w-8 text-primary" />
                )}
                <input type="file" className="hidden" onChange={handleChange} />
            </label>
            {field.value && (
                <button
                    type="button"
                    className="p-1 rounded absolute top-0 right-0 text-red-500"
                    onClick={removeFile}
                >
                    <XIcon className="w-5 h-5" />
                </button>
            )}
        </div>
    );
};

export default HeaderInput;
