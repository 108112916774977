import React, { Fragment, useEffect, useRef, useState } from "react";
import { ErrorMessage, Form, Formik, FormikConsumer } from "formik";
import {
    ApolloProvider,
    gql,
    useQuery,
    useMutation,
    useLazyQuery,
} from "@apollo/client";
import client from "./client";
import StepOne from "./steps/StepOne";
import StepTwo, { emptyOffering } from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import * as yup from "yup";
import ErrorContainer from "./c/ErrorContainer";
import animateCSS from "../utils/animateCSS";

const Debug = () => (
    <div
        style={{
            margin: "3rem 1rem",
            borderRadius: 4,
            background: "#f6f8fa",
            boxShadow: "0 0 1px  #eee inset",
            overflowX: "scroll",
        }}
    >
        <div
            style={{
                textTransform: "uppercase",
                fontSize: 11,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                fontWeight: 500,
                padding: ".5rem",
                background: "#555",
                color: "#fff",
                letterSpacing: "1px",
            }}
        >
            Formik State
        </div>
        <FormikConsumer>
            {({ validationSchema, validate, onSubmit, ...rest }) => (
                <pre
                    style={{
                        fontSize: ".85rem",
                        padding: ".25rem .5rem",
                        overflowX: "scroll",
                    }}
                >
                    {JSON.stringify(rest, null, 2)}
                </pre>
            )}
        </FormikConsumer>
    </div>
);

const StepCounter = ({ children, active }) => {
    const classNames = [
        "mr-3 text-white rounded-full w-6 h-6 flex items-center justify-center",
        "flex-shrink-0 text-sm",
        active ? "bg-blue-600" : "bg-gray-300",
    ];
    return (
        <span className={classNames.join(" ")} style={{ zIndex: 1 }}>
            {children}
        </span>
    );
};

const Wizard = ({ children, initialValues, onSubmit, isEdit }) => {
    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children);
    const [snapshot, setSnapshot] = useState(initialValues);

    useEffect(() => {
        const v = sessionStorage.getItem("programInitialValue");
        if (v && !isEdit) {
            setSnapshot(JSON.parse(v));
        }
    }, [isEdit]);

    useEffect(() => {
        if (!isEdit) {
            sessionStorage.setItem(
                "programInitialValue",
                JSON.stringify(snapshot)
            );
        }
    }, [snapshot, isEdit]);

    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const skipStep = () => {
        window.scrollTo(0, 0);
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    };

    const next = ({ generalError, ...values }) => {
        setSnapshot(values);
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    };

    const previous = ({ generalError, ...values }) => {
        setSnapshot(values);
        setStepNumber(Math.max(stepNumber - 1, 0));
    };

    const handleSubmit = async ({ generalError, ...values }, bag) => {
        window.scrollTo(0, 0);
        if (step.props.onSubmit) {
            await step.props.onSubmit(values, bag);
        }
        if (isLastStep) {
            sessionStorage.removeItem("programInitialValue");
            return onSubmit(values, bag);
        } else {
            bag.setTouched({});
            next(values);
        }
    };

    return (
        <Formik
            initialValues={{ ...snapshot, generalError: "" }}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={step.props.validationSchema}
        >
            {(formik) => (
                <Form className="flex items-stretch pb-12">
                    <div className="flex flex-col w-72 flex-shrink-0 pr-4">
                        {steps.map((s, i) => (
                            <p
                                key={i}
                                className="flex items-center py-4 text-sm font-semibold relative with-connecting-lines"
                            >
                                <StepCounter active={stepNumber >= i}>
                                    {i + 1}
                                </StepCounter>
                                <span>{s.props.title}</span>
                            </p>
                        ))}
                    </div>
                    <div className="w-full flex-shrink-0 max-w-screen-md">
                        {step}
                    </div>

                    <div className="fixed w-full px-4 py-6 bg-sticky sticky-shadow bottom-0 left-0 right-0">
                        <div className="container mx-auto flex items-center">
                            {stepNumber === 0 && (
                                <a
                                    className="border border-primary hover:bg-primary hover:text-white rounded text-primary px-3 py-2 mr-auto"
                                    href="/provider/programs"
                                >
                                    Back to Programs
                                </a>
                            )}
                            {stepNumber > 0 && (
                                <button
                                    className="border border-primary rounded text-primary hover:bg-primary hover:text-white px-3 py-2 mr-auto"
                                    onClick={() => previous(formik.values)}
                                    type="button"
                                >
                                    Go Back
                                </button>
                            )}

                            <div className="ml-auto flex items-center space-x-4">
                                <ErrorMessage
                                    name="generalError"
                                    component={ErrorContainer}
                                />
                                {stepNumber === 1 && (
                                    <button
                                        className="border border-primary rounded text-primary px-3 py-2"
                                        disabled={formik.isSubmitting}
                                        type="button"
                                        onClick={skipStep}
                                    >
                                        Skip this Step
                                    </button>
                                )}

                                {isLastStep && (
                                    <button
                                        className="border border-primary rounded text-primary px-3 py-2"
                                        disabled={formik.isSubmitting}
                                        type="button"
                                        onClick={() => {
                                            formik.setFieldValue(
                                                "status",
                                                "draft"
                                            );
                                            formik.submitForm();
                                        }}
                                    >
                                        Save as Draft
                                    </button>
                                )}
                                <button
                                    className="bg-primary hover:bg-primary-hover rounded text-white px-3 py-2"
                                    disabled={formik.isSubmitting}
                                    type="button"
                                    onClick={() => {
                                        formik.setFieldValue(
                                            "status",
                                            isLastStep ? "published" : "draft"
                                        );
                                        formik.validateForm().then(() => {
                                            formik.submitForm();
                                        });
                                    }}
                                >
                                    {isLastStep
                                        ? "Save and Publish"
                                        : "Continue"}
                                </button>
                            </div>
                        </div>
                    </div>
                    {process.env.NODE_ENV === "development" && <Debug />}
                </Form>
            )}
        </Formik>
    );
};

const WizardStep = ({ children }) => children;

export const emptyAccreditation = {
    province: "",
    area: "",
    region: "",
    documentType: "",
    dateAwarded: "",
};

const INITIAL_QUERY = gql`
    query GetInitialData($id: ID!) {
        program(id: $id) {
            id
            name
            creditUnit
            registrationFee
            description
            status
            header
            offerings {
                id
                slots
                addressRegion
                addressProvince
                addressArea
                addressLine1
                area {
                    id
                    name
                }
                schedules {
                    id
                    startDate
                    endDate
                }
            }
            professions {
                id
                name
            }
            programType {
                id
                name
                category
            }
        }
        programTypes {
            id
            name
        }
        professions {
            id
            name
        }
    }
`;

const SAVE_PROGRAM = gql`
    mutation SaveProgram($saveProgramInput: SaveProgramInput!) {
        saveProgram(input: $saveProgramInput) {
            errors
            success
            program {
                id
                name
                programType {
                    name
                }
                status
            }
            isEdit
        }
    }
`;

const tranformOfferingForForm = (o) => ({
    slots: o?.slots,
    addressArea: o?.addressArea,
    addressLine1: o?.addressLine1,
    addressProvince: o?.addressProvince,
    addressRegion: o?.addressRegion,
    id: o?.id,
    schedulesAttributes:
        o?.schedules?.map(({ id, startDate, endDate }) => ({
            id,
            startDate,
            endDate,
        })) || [],
});

const transformDataToInitialFormikData = (d) => ({
    // header: "",
    id: d?.id || "",
    name: d?.name || "",
    programTypeId: d?.programType?.id || "",
    creditUnit: d?.creditUnit || 0,
    // featuredSkills: [],
    description: d?.description || "",
    professionIds:
        d?.professions?.length > 0 ? d.professions.map((p) => p.id) : [],
    // allowAllProfessions: false,
    registrationFee: d?.registrationFee || 0,
    // applicationRequirements: "",
    // accreditations: [emptyAccreditation],
    offeringsAttributes:
        d?.offerings?.length > 0
            ? d.offerings.map(tranformOfferingForForm)
            : [emptyOffering],
    status: d?.status || "draft",
});

const StepOneSchema = yup.object().shape({
    name: yup.string().required("This field is required."),
    programTypeId: yup.string().required("This field is required."),
    creditUnit: yup
        .number()
        .integer("Must be a valid number.")
        .typeError("Must be a valid number.")
        .min(0, "Min value is 0.")
        .nullable()
        .transform((value, originalValue) =>
            String(originalValue).trim() === "" ? null : value
        ),
    description: yup.string(),
});

const StepTwoSchema = yup.object().shape({
    registrationFee: yup
        .number()
        .integer("Must be a valid number.")
        .typeError("Must be a valid number.")
        .min(0, "Minimum value is 0.")
        .nullable()
        .transform((value, originalValue) =>
            String(originalValue).trim() === "" ? null : value
        ),
    offeringsAttributes: yup
        .array()
        .of(
            yup.object().shape({
                slots: yup
                    .number()
                    .integer("Must be a valid number.")
                    .typeError("Must be a valid number.")
                    .min(1, "Minimum number of slots is 1.")
                    .nullable()
                    .transform((value, originalValue) =>
                        String(originalValue).trim() === "" ? null : value
                    ),
                addressArea: yup.string().required("This field is required"),
                addressLine1: yup.string(),
                addressProvince: yup
                    .string()
                    .required("This field is required"),
                addressRegion: yup.string().required("This field is required"),
                schedulesAttributes: yup
                    .array()
                    .of(
                        yup.object().shape({
                            startDate: yup
                                .string()
                                .required("This field is required"),
                            endDate: yup
                                .string()
                                .required("This field is required"),
                        })
                    )
                    .min(1, "Must have at least one schedule")
                    .ensure(),
            })
        )
        .min(1, "Must have at least one offering")
        .compact()
        .ensure(),
});

const StepThreeSchema = StepOneSchema.concat(StepTwoSchema);

const ALERT_CLASS = {
    success: "bg-green-500 text-white",
    danger: "bg-red-500 text-white",
    alert: "bg-red-500 text-white",
    info: "bg-blue-300 text-gray-800",
    warning: "bg-yellow-600 text-white",
};

const Alert = ({ closeAlert, children, timeout = 3000, type }) => {
    const el = useRef(null);
    let timeoutId;
    function hideAlert() {
        animateCSS(el.current, "fadeOut").then(() => {
            timeoutId && clearTimeout(timeoutId);
            closeAlert();
        });
    }

    useEffect(() => {
        if (timeout > 0) {
            console.log(timeout);
            timeoutId = setTimeout(hideAlert, timeout);
        }
    }, [timeout]);
    return (
        <div
            ref={el}
            className={`mx-auto mb-8 px-4 sm:px-6 lg:px-8 py-2 flex items-center animate__animated animate__fadeIn ${ALERT_CLASS[type]}`}
        >
            {children}
            <button
                type="button"
                className="ml-auto p-1 rounded-md text-current focus:outline-none"
                aria-label="Close"
                onClick={hideAlert}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
};

const emptyAlert = {
    show: false,
    type: "success",
    message: "",
    timeout: 3000,
};

const ProgramForm = ({ programData, provider }) => {
    const { data, loading } = useQuery(INITIAL_QUERY, {
        variables: { id: programData?.id || "" },
    });
    const [saveProgram] = useMutation(SAVE_PROGRAM);
    const [showAlert, setShowAlert] = useState(emptyAlert);
    if (loading) return null;

    const programTypeOptions =
        data?.programTypes?.map((p) => ({
            value: p.id,
            label: p.name,
        })) || [];

    const professionOptions =
        data?.professions?.map((p) => ({
            value: p.id,
            label: p.name,
        })) || [];

    const provider_id = programData?.provider_id;
    const isEdit = Boolean(programData?.id);
    const programId = programData?.id;

    return (
        <Fragment>
            {showAlert.show && (
                <Alert
                    type={showAlert.type}
                    closeAlert={() => setShowAlert(emptyAlert)}
                    timeout={showAlert.timeout}
                >
                    {showAlert.message}
                </Alert>
            )}
            <Wizard
                initialValues={transformDataToInitialFormikData(data?.program)}
                isEdit={isEdit}
                programId={programId}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                    setSubmitting(true);

                    try {
                        const { data } = await saveProgram({
                            variables: {
                                saveProgramInput: {
                                    attributes: { ...values },
                                },
                            },
                        });
                        sessionStorage.removeItem("programInitialValue");
                        setSubmitting(false);
                        const status = data?.saveProgram?.program?.status;
                        setShowAlert({
                            ...emptyAlert,
                            show: true,
                            message: `Program successfuly ${
                                status === "published"
                                    ? status
                                    : "saved as draft."
                            }`,
                        });
                        const programId = data?.saveProgram?.program?.id;
                        const isEdit = data?.saveProgram?.isEdit;
                        setTimeout(() => {
                            if (programId && !isEdit) {
                                window.location = `/provider/programs/${programId}`;
                            }
                        }, emptyAlert.timeout + 500);
                    } catch (error) {
                        setSubmitting(false);
                        setShowAlert({
                            ...emptyAlert,
                            show: true,
                            message: error.message,
                            type: "danger",
                        });
                        console.log(error);
                    }
                }}
            >
                <WizardStep
                    title="Program Information"
                    validationSchema={StepOneSchema}
                >
                    <StepOne
                        programTypeOptions={programTypeOptions}
                        professionOptions={professionOptions}
                    />
                </WizardStep>
                <WizardStep title="Schedules" validationSchema={StepTwoSchema}>
                    <StepTwo />
                </WizardStep>
                <WizardStep title="Review and Publish">
                    <StepThree
                        programTypeOptions={programTypeOptions}
                        professionOptions={professionOptions}
                        provider={provider}
                    />
                </WizardStep>
            </Wizard>
        </Fragment>
    );
};

const App = (p) => (
    <ApolloProvider client={client}>
        <ProgramForm {...p} />
    </ApolloProvider>
);

export default App;
