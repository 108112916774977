import React, { Fragment, useEffect } from "react";
import { Field, FieldArray, useFormikContext, ErrorMessage } from "formik";
import FormCard from "../c/FormCard";
import { PlusIcon, TrashIcon, XIcon } from "@heroicons/react/solid";
import AddressDetails from "../c/AddressDetails";
import DateField from "../c/DateField";
import ErrorContainer from "../c/ErrorContainer";

const emptySched = {
    startDate: "",
    endDate: "",
};

export const emptyOffering = {
    slots: 0,
    schedulesAttributes: [emptySched],
    addressLine1: "",
    addressProvince: "",
    addressRegion: "",
    addressArea: "",
};

const RenderSchedule = ({
    remove,
    push,
    name,
    form,
    idx,
    schedulesAttributes,
}) => {
    const scheds = name?.split(".").reduce((cur, acc) => {
        return cur[acc];
    }, form?.values);

    useEffect(() => {
        scheds.forEach((s, i) => {
            const sd = new Date(s.startDate);
            const ed = new Date(s.endDate);
            const diffTime = sd - ed;
            if (diffTime > 0 && !isNaN(diffTime)) {
                form.setFieldValue(`${name}.${i}.endDate`, "");
            }
        });
    }, [scheds]);
    return (
        <div className="grid grid-cols-8 gap-4">
            <div className="bg-sticky border border-sticky-border rounded p-4 col-span-full grid grid-cols-4 gap-4">
                {schedulesAttributes?.map((_, i) => (
                    <Fragment key={i}>
                        <div className="col-span-2 flex flex-col">
                            <label
                                htmlFor="startDate"
                                className="label font-semibold"
                            >
                                Start Date
                            </label>
                            <Field
                                type="datetime-local"
                                name={`offeringsAttributes.${idx}.schedulesAttributes.${i}.startDate`}
                                id="startDate"
                                className="text-input"
                                component={DateField}
                                minDate={new Date()}
                            />
                            <ErrorMessage
                                name={`offeringsAttributes.${idx}.schedulesAttributes.${i}.startDate`}
                                component={ErrorContainer}
                            />
                        </div>
                        <div className="col-span-2 flex">
                            <div className="flex flex-col w-full">
                                <label
                                    htmlFor="endDate"
                                    className="label font-semibold"
                                >
                                    End Date
                                </label>
                                <Field
                                    type="datetime-local"
                                    name={`offeringsAttributes.${idx}.schedulesAttributes.${i}.endDate`}
                                    id="endDate"
                                    className="text-input"
                                    component={DateField}
                                    minDate={scheds[i]?.startDate || new Date()}
                                />
                                <ErrorMessage
                                    name={`offeringsAttributes.${idx}.schedulesAttributes.${i}.endDate`}
                                    component={ErrorContainer}
                                />
                            </div>
                            <button
                                className="text-deleteBtn bg-deleteBtn-bg hover:bg-deleteBtn-hover flex h-6 w-6 ml-1 flex items-center justify-center rounded mt-7"
                                type="button"
                                onClick={() => remove(i)}
                            >
                                <XIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </Fragment>
                ))}
                <button
                    className="flex items-center col-span-full"
                    type="button"
                    style={{ color: "#0786BF" }}
                    onClick={() => push(emptySched)}
                >
                    <PlusIcon className="h-5 w-5" />
                    <span className="ml-2">Add more days</span>
                </button>
            </div>
        </div>
    );
};

const Offering = ({
    idx,
    schedulesAttributes = [],
    addressProvince,
    addressRegion,
    removeOffer,
}) => {
    const { values } = useFormikContext();
    return (
        <div className="mt-4">
            <h5 className="font-semibold text-xl mb-8 flex">
                <span className="mr-2">Offering {idx + 1}</span>
                <button
                    type="button"
                    className="flex items-center text-sm rounded p-2 text-deleteBtn bg-deleteBtn-bg hover:bg-deleteBtn-hover ml-auto"
                    onClick={removeOffer}
                >
                    <TrashIcon className="h-5 w-5" />
                    <span className="ml-1">Delete</span>
                </button>
            </h5>
            <div className="w-full mb-4">
                <label htmlFor="slots" className="label font-semibold">
                    Available Slots
                </label>
                <Field
                    type="number"
                    name={`offeringsAttributes.${idx}.slots`}
                    id="slots"
                    className="text-input"
                />
                <ErrorMessage
                    name={`offeringsAttributes.${idx}.slots`}
                    component={ErrorContainer}
                />
            </div>
            <div className="w-full mb-4">
                <label htmlFor="venue" className="label font-semibold">
                    Venue Address
                </label>
                <Field
                    type="text"
                    name={`offeringsAttributes.${idx}.addressLine1`}
                    id="venue"
                    className="text-input"
                />
                <ErrorMessage
                    name={`offeringsAttributes.${idx}.addressLine1`}
                    component={ErrorContainer}
                />
            </div>
            <AddressDetails
                provinceName={`offeringsAttributes.${idx}.addressProvince`}
                areaName={`offeringsAttributes.${idx}.addressArea`}
                regionName={`offeringsAttributes.${idx}.addressRegion`}
                provinceValue={addressProvince}
                regionValue={addressRegion}
            />

            <FieldArray
                name={`offeringsAttributes.${idx}.schedulesAttributes`}
                render={(p) => (
                    <RenderSchedule
                        {...p}
                        idx={idx}
                        schedulesAttributes={schedulesAttributes}
                    />
                )}
            />
            <ErrorMessage
                name={`offeringsAttributes.${idx}.schedulesAttributes`}
                component={ErrorContainer}
            />
        </div>
    );
};

const StepTwo = () => {
    const { values } = useFormikContext();
    return (
        <FormCard title="Program Offerings">
            <p className="pb-4">
                For accredited programs, you may upload proof to show potential
                applicants. If you are not yet accredited, you can apply for
                accreditation after creating this program.
            </p>
            <FieldArray
                name="offeringsAttributes"
                render={(arrayHelpers) => (
                    <Fragment>
                        {values?.offeringsAttributes.map((o, i) => (
                            <div
                                key={i}
                                className="-ml-8 -mr-8 py-8 border-t px-8"
                            >
                                <Offering
                                    idx={i}
                                    {...o}
                                    removeOffer={() => arrayHelpers.remove(i)}
                                />
                            </div>
                        ))}
                        <ErrorMessage
                            name={`offeringsAttributes`}
                            component={ErrorContainer}
                        />
                        <hr className="-ml-8 -mr-8 pt-8 border-t" />
                        <button
                            className="border-primary text-primary hover:bg-primary hover:text-white border justify-center py-2 rounded  flex items-center w-full"
                            type="button"
                            onClick={() => arrayHelpers.push(emptyOffering)}
                        >
                            <PlusIcon className="h-5 w-5" />
                            <span className="ml-2">
                                Add{" "}
                                {values?.offeringsAttributes.length
                                    ? "another"
                                    : "new"}{" "}
                                offering
                            </span>
                        </button>
                    </Fragment>
                )}
            />
        </FormCard>
    );
};

export default StepTwo;
