import React from "react";
import { gql, useQuery } from "@apollo/client";
import { ErrorMessage, Field } from "formik";
import Select from "react-select";
import ErrorContainer from "./ErrorContainer";

const ADDRESS_QUERY = gql`
    query GetAddressOptions($regionName: String, $provinceName: String) {
        regions {
            id
            name
        }

        provincesByRegion(regionName: $regionName) {
            id
            name
        }

        areasByProvince(provinceName: $provinceName) {
            id
            name
        }
    }
`;

const AddressDetails = ({
    regionValue,
    regionName,
    provinceValue,
    provinceName,
    areaName,
    required = true,
}) => {
    const { data, loading } = useQuery(ADDRESS_QUERY, {
        variables: {
            regionName: regionValue,
            provinceName: provinceValue,
        },
        nextFetchPolicy: "cache-first",
    });

    const regionOptions =
        data?.regions?.map((r) => ({
            label: r.name,
            value: r.name,
        })) || [];
    const provinceOptions =
        data?.provincesByRegion?.map((r) => ({
            label: r.name,
            value: r.name,
        })) || [];
    const areaOptions =
        data?.areasByProvince?.map((r) => ({
            label: r.name,
            value: r.name,
        })) || [];

    return (
        <div className="grid grid-cols-8 gap-4 col-span-full pb-8">
            <div className="col-span-4">
                <label
                    className={`label font-semibold mb-1 ${
                        required ? "form-required" : ""
                    }`}
                >
                    Region
                </label>
                <Field
                    name={regionName}
                    component={({ field, form }) => (
                        <Select
                            className="ReactSelect"
                            classNamePrefix="ReactSelect"
                            value={regionOptions.find(
                                ({ value }) => value === field.value
                            )}
                            required={required}
                            options={regionOptions}
                            isDisabled={loading}
                            placeholder={field.value || "Select One"}
                            onChange={(v) => {
                                form.setFieldValue(field.name, v.value);
                                /* reset other fields value when changed */
                                form.setFieldValue(provinceName, "");
                                form.setFieldValue(areaName, "");
                            }}
                        />
                    )}
                />
                <ErrorMessage name={regionName} component={ErrorContainer} />
            </div>
            <div className="col-span-4">
                <label
                    className={`label font-semibold mb-1 ${
                        required ? "form-required" : ""
                    }`}
                >
                    Province
                </label>
                <Field
                    name={provinceName}
                    component={({ field, form }) => (
                        <Select
                            className="ReactSelect"
                            classNamePrefix="ReactSelect"
                            value={provinceOptions.find(
                                ({ value }) => value === field.value
                            )}
                            required={required}
                            placeholder={field.value || "Select One"}
                            options={provinceOptions}
                            noOptionsMessage={() => "Select a region first"}
                            isDisabled={loading || !regionValue}
                            onChange={(v) => {
                                form.setFieldValue(field.name, v.value);
                                /* reset area value when changed */
                                form.setFieldValue(areaName, "");
                            }}
                        />
                    )}
                />
                <ErrorMessage name={provinceName} component={ErrorContainer} />
            </div>
            <div className="col-span-4">
                <label
                    className={`label font-semibold mb-1 ${
                        required ? "form-required" : ""
                    }`}
                >
                    Municipality
                </label>
                <Field
                    name={areaName}
                    component={({ field, form }) => (
                        <Select
                            className="ReactSelect"
                            classNamePrefix="ReactSelect"
                            placeholder={field.value || "Select One"}
                            value={areaOptions.find(
                                ({ value }) => value === field.value
                            )}
                            required={required}
                            noOptionsMessage={() => "Select a province first"}
                            options={areaOptions}
                            isDisabled={loading || !provinceValue}
                            onChange={(v) => {
                                form.setFieldValue(field.name, v.value);
                            }}
                        />
                    )}
                />
                <ErrorMessage name={areaName} component={ErrorContainer} />
            </div>
        </div>
    );
};

export default AddressDetails;
