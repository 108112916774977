import React from "react";

const ErrorContainer = ({ children }) => {
    const isString = typeof children === "string";

    if (!isString) return null;
    return <div className="py-1 text-red-500 text-sm">{children}</div>;
};

export default ErrorContainer;
