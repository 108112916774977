import React from "react";
import { render } from "react-dom";
// import ExplorePrograms from "./ExplorePrograms";
import ProgramForm from "./ProgramForm";

function mountApps() {
    // const exploreProgramsContainer = document.getElementById("explorePrograms");
    // if (exploreProgramsContainer) {
    //     render(<ExplorePrograms />, exploreProgramsContainer);
    // }
    const programForm = document.getElementById("newProgram");
    if (programForm) {
        const programData = programForm?.dataset?.program
            ? JSON.parse(programForm?.dataset?.program)
            : null;
        const provider = programForm?.dataset?.provider
            ? JSON.parse(programForm?.dataset?.provider)
            : null;
        const offerings = programForm?.dataset?.offerings
            ? JSON.parse(programForm?.dataset?.offerings)
            : null;
        console.log({ offerings });
        render(
            <ProgramForm
                programData={{ ...programData, offeringsAttributes: offerings }}
                provider={provider}
            />,
            programForm
        );
    }
}

function initializeApps() {
    document.addEventListener("turbolinks:load", mountApps);
}

export default initializeApps;
