import { hideOnClickOutside } from "../javascript/utils/helper";

function initializedDropdownNavLinkComponent(event) {
    const $dropdownNavLink =
        document.getElementsByClassName("js-DropdownNavLink");

    [...$dropdownNavLink].forEach((element) => {
        if (element) {
            element.addEventListener("click", (e) => {
                const $t = e.currentTarget.nextElementSibling;
                $t.style.display =
                    $t.style.display === "none" ? "block" : "none";
            });

            hideOnClickOutside(element);
        }
    });
}
document.addEventListener(
    "turbolinks:load",
    initializedDropdownNavLinkComponent
);
