import React from "react";
import { Field, ErrorMessage } from "formik";
import FormCard from "../c/FormCard";
import ErrorContainer from "../c/ErrorContainer";
import Select from "react-select";
import ProgramAccreditations from "../c/ProgramAccreditations";
import HeaderInput from "../c/HeaderInput";
import CreatableSelectWithMaxOptions from "../c/CreatableSelectWithMaxOptions";

const StepOne = ({ programTypeOptions, professionOptions }) => (
    <div className="space-y-8">
        {/*<FormCard title="Upload Program Image">
            <p>
                For accredited programs, you may upload proof to show potential
                applicants. If you are not yet accredited, you can apply for
                accreditation after creating this program.
            </p>
            <Field name="header" component={HeaderInput} />
        </FormCard>*/}
        <FormCard title="Basic Details">
            <div className="grid grid-cols-8 gap-4">
                <div className="col-span-full">
                    <label
                        htmlFor="programTitle"
                        className="label font-semibold"
                    >
                        Program Title
                    </label>
                    <Field
                        type="text"
                        name="name"
                        id="programTitle"
                        className="text-input"
                    />
                    <ErrorMessage name="name" component={ErrorContainer} />
                    <span className="text-xs">
                        Please keep your program titles short and concise
                    </span>
                </div>

                <div className="col-span-4">
                    <label className="label font-semibold mb-1">
                        Program Type
                    </label>
                    <Field
                        name="programTypeId"
                        component={({ field, form }) => (
                            <Select
                                className="ReactSelect"
                                classNamePrefix="ReactSelect"
                                value={programTypeOptions.find(
                                    ({ value }) => value === field.value
                                )}
                                options={programTypeOptions}
                                onChange={(v) => {
                                    form.setFieldValue(field.name, v.value);
                                }}
                                onBlur={field.onBlur}
                            />
                        )}
                    />
                    <ErrorMessage
                        name="programTypeId"
                        component={ErrorContainer}
                    />
                </div>
                <div className="col-span-4">
                    <label className="label font-semibold" htmlFor="creditUnit">
                        Number of Units
                    </label>
                    <Field
                        type="number"
                        name="creditUnit"
                        id="creditUnit"
                        className="text-input"
                    />
                    <ErrorMessage
                        name="creditUnit"
                        component={ErrorContainer}
                    />
                </div>
                {/*<div className="col-span-full">
                    <label
                        className="label font-semibold mb-1"
                        htmlFor="featuredSkills"
                    >
                        Featured Skills
                    </label>
                    <Field
                        id="featuredSkills"
                        name="featuredSkills"
                        component={({ field, form }) => (
                            <CreatableSelectWithMaxOptions
                                className="ReactSelect"
                                classNamePrefix="ReactSelect"
                                value={form.values.featuredSkills}
                                onChange={(v) => {
                                    form.setFieldValue(field.name, v);
                                }}
                                onBlur={form.onBlur}
                            />
                        )}
                    />
                    <span className="text-xs">
                        You can add up to 5 skills, or create your own if it is
                        not available in the selection.
                    </span>
                </div>*/}
            </div>
            <div className="col-span-full">
                <label
                    className="label font-semibold mb-1"
                    htmlFor="programDescription"
                >
                    Program Description
                </label>
                <Field
                    type="textarea"
                    name="description"
                    id="programDescription"
                    component={({ field }) => (
                        <textarea className="text-input" {...field}></textarea>
                    )}
                />
                <ErrorMessage name="description" component={ErrorContainer} />
            </div>
        </FormCard>

        <FormCard title="Registration Details">
            <div className="grid grid-cols-8 gap-4">
                <div className="col-span-full">
                    <label className="label font-semibold mb-1">
                        Professions Admitted
                    </label>
                    <Field
                        name="professionIds"
                        component={({ field, form }) => (
                            <Select
                                isMulti
                                className="ReactSelect"
                                classNamePrefix="ReactSelect"
                                isDisabled={form.values.allowAllProfessions}
                                value={professionOptions.filter(({ value }) =>
                                    field.value.includes(value)
                                )}
                                options={professionOptions}
                                onChange={(v) => {
                                    const arrayOfVals = v.map((v) => v.value);
                                    form.setFieldValue(field.name, arrayOfVals);
                                }}
                                onBlur={field.onBlur}
                            />
                        )}
                    />
                </div>
                {/*<div className="col-span-full">
                    <div className="flex items-center space-x-2">
                        <Field
                            type="checkbox"
                            name="allowAllProfessions"
                            id="allowAllProfessions"
                            className="checkbox"
                        />
                        <label
                            htmlFor="allowAllProfessions"
                            className="checkbox-label"
                        >
                            Admit all professions to this program
                        </label>
                    </div>
                </div>*/}
                <div className="col-span-full">
                    <label
                        htmlFor="registrationFee"
                        className="label font-semibold"
                    >
                        Program Fee
                    </label>
                    <Field
                        type="number"
                        name="registrationFee"
                        id="registrationFee"
                        className="text-input"
                    />
                    <ErrorMessage
                        name="registrationFee"
                        component={ErrorContainer}
                    />
                </div>
                {/* <div className="col-span-full">
                    <label
                        className="label font-semibold mb-1"
                        htmlFor="applicationRequirements"
                    >
                        Application Requirements
                    </label>
                    <Field
                        type="textarea"
                        name="applicationRequirements"
                        id="applicationRequirements"
                        component={({ field }) => (
                            <textarea
                                className="text-input"
                                {...field}
                            ></textarea>
                        )}
                    />
                </div> */}
            </div>
        </FormCard>
        {/*<ProgramAccreditations />*/}
    </div>
);

export default StepOne;
