import { useFormikContext } from "formik";
import React, { Fragment } from "react";

const Tag = ({ children, rounded }) => {
    const className = [
        "text-xs font-semibold px-3",
        "py-1 border border-primary-lightest",
        "bg-primary-lightest text-primary",
        rounded ? "rounded-full px-4" : "rounded",
    ].join(" ");
    return <span className={className}>{children}</span>;
};

const ProgramDetails = ({ featuredSkills, description }) => (
    <div className="flex items-start">
        <div className="max-w-prose mr-4">
            <h3 className="mb-5 font-semibold text-xl">Program Details</h3>
            <div className="space-y-4 mb-6">
                <p>{description || "No description."}</p>
            </div>
            {/* <h3 className="mb-5 font-semibold text-xl">
                Registration Requirements
            </h3>
            <div className="space-y-4 mb-6">
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Nihil, aperiam quae blanditiis voluptatem reiciendis
                    expedita iure beatae dignissimos perspiciatis laudantium
                    quasi, vel doloribus eos, veniam tenetur non molestiae
                    delectus numquam.
                </p>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Nihil, aperiam quae blanditiis voluptatem reiciendis
                    expedita iure beatae dignissimos perspiciatis laudantium
                    quasi, vel doloribus eos, veniam tenetur non molestiae
                    delectus numquam.
                </p>
            </div> */}
            <h3 className="mb-5 font-semibold text-xl">Skills Offered</h3>
            <div className="space-y-4 space-x-4 mb-4">
                {featuredSkills.map(({ label }, i) => (
                    <Tag rounded key={i}>
                        {label}
                    </Tag>
                ))}
            </div>
        </div>
    </div>
);

const Ratings = () => (
    <ul className="flex flex-row-reverse justify-end items-center list-none text-yellow-500">
        {new Array(5).fill(1).map((_, i) => (
            <li className="h-4 w-4 mr-1" key={i}>
                <svg
                    key={i}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    />
                </svg>
            </li>
        ))}
    </ul>
);

const formatDate = (date) =>
    new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    }).format(date);
const formatTime = (date) =>
    new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
    }).format(date);

const AddressBar = ({ venue, province, region, area }) => (
    <div className="flex flex-col space-y-1 text-base text-body">
        <p>{venue || "-"}</p>
        <p>
            {area ? area + ", " : ""}
            {province ? province + ", " : ""}
            {region || ""}
        </p>
    </div>
);

const OfferingItem = ({ slots, schedulesAttributes = [], fee, ...address }) => (
    <div className="shadow p-5 flex items-start">
        <div className="space-y-3 w-3/5">
            <span className="font-semibold text-xs uppercase text-primary">
                {slots !== 1 ? `${slots || 0} slots` : "1 slot"}
            </span>
            {schedulesAttributes.length > 0 ? (
                schedulesAttributes.map(({ startDate, endDate }, i) => (
                    <Fragment key={i}>
                        <p>
                            {startDate ? formatDate(new Date(startDate)) : ""}
                            {endDate
                                ? `– ${formatDate(new Date(endDate))}`
                                : ""}
                        </p>
                        <p className="text-body">
                            {startDate ? formatTime(new Date(startDate)) : ""}
                            {endDate ? `–${formatTime(new Date(endDate))}` : ""}
                        </p>
                    </Fragment>
                ))
            ) : (
                <p>No schedules yet.</p>
            )}

            <AddressBar
                venue={address.addressLine1}
                province={address.addressProvince}
                region={address.addressRegion}
                area={address.addressArea}
            />
        </div>
        <div className="w-1/5">
            <p className="text-gray-500 text-xs font-semibold">Program Fee</p>
            <p className="font-semibold">{fee}</p>
        </div>
    </div>
);

const ProgramSummaryDetail = ({ name, programType, tags, provider }) => {
    return (
        <Fragment>
            <h3 className="uppercase mb-3 text-sm font-semibold text-primary-light">
                {programType?.label}
            </h3>
            <h1 className="text-2xl">{name}</h1>
            <div className="text-sm mb-5">
                <div className="flex items-center mt-3 mb-4">
                    <Ratings />
                    <span className="ml-2 text-yellow-500">0.0</span>
                    <span className="ml-2">No ratings yet.</span>
                </div>
                <p>
                    by <span className="underline">{provider?.name}</span>
                </p>
            </div>
            <div className="flex items-center space-x-3">
                {tags.map((t, i) => (
                    <Tag key={i}>{t}</Tag>
                ))}
            </div>
        </Fragment>
    );
};
const StepThree = ({ programTypeOptions, provider }) => {
    const { values } = useFormikContext();
    const programType = programTypeOptions.find(
        (o) => o.value === values.programTypeId
    );

    const offeringsAttributes = values?.offeringsAttributes;
    const hasOfferings = offeringsAttributes.length > 0;
    return (
        <Fragment>
            <div className="mx-auto px-4 z-1 relative">
                <div className="p-8 bg-white shadow rounded-sm">
                    <div className="mx-auto w-full">
                        <div className="flex items-center">
                            <div className="w-3/5 mr-4">
                                <ProgramSummaryDetail
                                    name={values.name}
                                    programType={programType}
                                    provider={provider}
                                    tags={[
                                        values?.creditUnit
                                            ? `${values.creditUnit} CPD Unit${
                                                  values.creditUnit != 1
                                                      ? "s"
                                                      : ""
                                              }`
                                            : null,
                                        hasOfferings
                                            ? `${
                                                  offeringsAttributes.length
                                              } open schedule${
                                                  offeringsAttributes.length !==
                                                  1
                                                      ? "s"
                                                      : ""
                                              }`
                                            : null,
                                    ].filter(Boolean)}
                                />
                            </div>
                            <div className="bg-cover bg-center h-48 w-2/5 bg-primary" />
                        </div>
                        <hr className="my-12" />
                        <div className="space-y-12">
                            {hasOfferings ? (
                                offeringsAttributes.map((o, i) => (
                                    <OfferingItem
                                        key={i}
                                        {...o}
                                        fee={
                                            Number(
                                                !isNaN(
                                                    Number(
                                                        values?.registrationFee
                                                    )
                                                ) && values?.registrationFee
                                            ) > 0
                                                ? new Intl.NumberFormat(
                                                      "en-US",
                                                      {
                                                          style: "currency",
                                                          currency: "PHP",
                                                      }
                                                  ).format(
                                                      values?.registrationFee
                                                  )
                                                : "Free"
                                        }
                                    />
                                ))
                            ) : (
                                <p>No schedules yet.</p>
                            )}
                        </div>
                        <hr className="my-12" />
                        {/*<ProgramDetails
                            featuredSkills={values?.featuredSkills}
                        />*/}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default StepThree;
